::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gray;
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 20px;
}
