/* Stile del contenitore principale */
.container {
    background-color: #82e0aa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Stile generale del picker */
.react-time-picker {
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff; /* Colore di sfondo del picker */
}

/* Stile dell'input */
.react-time-picker__inputGroup {
    display: flex;
    align-items: center;
}

.react-time-picker__inputGroup__input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px;
}

/* Stile dell'icona per cancellare l'input */
.react-time-picker__clear-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #999;
}

.react-time-picker__clear-button:hover {
    color: #333;
}

/* Stile del clock */
.custom-clock {
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Stile dei pulsanti del clock */
.react-time-picker__clock-action-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
}

.react-time-picker__clock-action-button:hover {
    background-color: #0056b3;
}

/* Stile dell'orario selezionato */
.react-time-picker__time-input {
    color: #333;
    font-size: 18px;
}
