.rbc-row-content {
    z-index: 0;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid white;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid white;
}

.rbc-month-view {
    border: 1px solid white;
}

.rbc-toolbar button {
    border: 1px solid white;
}

.rbc-month-view {
    border: 1px solid white;
}

.rbc-header {
    border-bottom: 1px solid white;
}

.rbc-header + .rbc-header {
    border-left: 1px solid white;
}

.rbc-btn-group button {
    background-color: rgb(0, 0, 0, 0.1);
    color: #000000;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
}

.rbc-btn-group button:hover {
    background-color:  rgb(0, 0, 0, 0.3);
}

.rbc-btn-group .rbc-active {
    background-color: rgb(0, 0, 0, 0.3) !important;
    color: inherit !important;
    border: none !important;
    box-shadow: none !important;
}
