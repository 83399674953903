/* Cambia lo sfondo del calendario */
.react-datepicker {
    background-color: #f0f8ff;
    border: 0;
    border-radius: 8px;
}

/* Cambia il colore dei giorni */
.react-datepicker__day {
    color: #333;
}

/* Cambia lo stile dei giorni selezionati */
.react-datepicker__day--selected {
    background-color: #000000;
    color: white;
}

/* Cambia lo stile del giorno attivo (hover) */
.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
    background-color: #000000;
    color: white;
}

/* Cambia lo stile dell'intestazione (mesi e giorni della settimana) */
.react-datepicker__header {
    background-color: #E5E7E9;
    border-bottom: 0;
    color: white;
}

.react-datepicker__current-month {
    font-weight: bold;
}

/* Cambia lo stile dei bottoni di navigazione */
.react-datepicker__navigation {
    top: 10px;
    line-height: 1.7rem;
}

.react-datepicker__navigation--previous {
    border-right-color: #1976d2;
}
.react-datepicker__navigation--next {
    border-left-color: #1976d2;
}

.custom-input {
    width: 200px;
    padding: 10px;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    color: #000000;
    background-color: #ffffff;
}
