.react-calendar-timeline .rct-header-root {
    background-color: transparent;
    border-bottom: 0;
    border-radius: 5px;
}

.react-calendar-timeline .rct-dateHeader-primary {
    background-color: initial;
    border-left: 0;
    border-right: 0;
    border-radius: 5px;
    color: unset;
}

.react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: unset;
    transition: background-color 0.2s ease-in-out;
}

/*questo permette di fare l'effetto di selezione quando passi sopra le date del calendario con il mouse*/
.react-calendar-timeline .rct-dateHeader:hover {
    background-color: #00000033;
}

.react-calendar-timeline .rct-calendar-header {
    border-width: 0;
}

.element.style {
    z-index: 0;
}

.react-calendar-timeline .rct-scroll {
    overflow-x: hidden;
    overflow-y: hidden;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid white;
    z-index: 0;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid white;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: 0;
    font-size: 14px;
}

.react-calendar-timeline .rct-sidebar {
    border-right: 0;
}

.react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
}
